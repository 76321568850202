export default {
    name: "neo-tab",
    components: {},
    props: {
        tabs: Array,
        active: String,
        isModeReadOnly: Boolean,
        tabHeight: {
            type: String,
            default: "3rem",
        },
        margin: {
            type: String,
            default: "0.4rem 0",
        },
        background: {
            type: String,
            default: "transparent",
        },
    },
    data() {
        return {
            currentTab: "",
            hoveredOver: false,
        };
    },
    computed: {
        getGridTemplateCols() {
            return `repeat(${this.activeTabs.length}, minmax(1rem, auto))`;
        },
        activeTabs() {
            return this.tabs.filter((tab) => {
                if (this.isModeReadOnly) {
                    return tab.readOnly;
                } else {
                    return tab.enabled;
                }
            });
        },
        getCurrentTab() {
            if (this.active) {
                this.currentTab = this.active;
                return this.active;
            }
        },
    },
    mounted() {
        this.active ? (this.currentTab = this.active) : "";
    },
    methods: {
        onSelect(tab) {
            this.currentTab = tab;
            this.$emit("tabChange", tab);
        },
    },
};
