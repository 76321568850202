import { render, staticRenderFns } from "./bvd.html?vue&type=template&id=4b854e34&scoped=true&"
import script from "./bvd.js?vue&type=script&lang=js&"
export * from "./bvd.js?vue&type=script&lang=js&"
import style0 from "./bvd.scss?vue&type=style&index=0&id=4b854e34&prod&scoped=true&lang=scss&"
import style1 from "@/components/monitoring-differential-highlighter/monitoring-differential-highlighter.scss?vue&type=style&index=1&id=4b854e34&prod&scoped=true&lang=scss&"
import style2 from "./index.vue?vue&type=style&index=2&id=4b854e34&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b854e34",
  null
  
)

export default component.exports