import NeoAnalyse from "@/components/analyse";
import {DateTime} from "luxon";
import NeoLoader from "@/components/loader";
import Tags from "@/components/tags";
import Tag from "@/components/tag";
import printDataMixin from "@/mixins/printDataMixin";
import highlighterMixin from "@/mixins/highlighterMixin";
import {get, startCase} from "lodash";
import {KlChart} from "@/components/vue-keylines";
import {formatDateToUTC} from "@/utils/functions";
import {EventBus} from "@/main.js";
import {getJurisdictionName} from "@/utils/functions";

export default {
    name: "openCorporates",
    components: {
        NeoAnalyse,
        NeoLoader,
        "neo-tags": Tags,
        "neo-tag": Tag,
        KlChart,
    },
    mixins: [printDataMixin, highlighterMixin],
    props: {
        companyDetails: {type: Object},
        isComparisonModeOn: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            startCase: startCase,
            stabilizing: false,
        };
    },
    computed: {},
    mounted() {
        this.highlightChanges();
    },
    methods: {
        get,
        getJurisdictionName,
        commaJoin(...values) {
            return values.filter((e) => e != null && e != "").join(", ");
        },
        joinArray(arr, sep = ", ") {
            return arr.filter((e) => e != null && e != "").join(sep);
        },
        formatDateFromISO(dt, format = "d MMMM yyyy") {
            return DateTime.fromISO(dt).toFormat(format);
        },
        formatDate(dt, format = "d MMMM yyyy") {
            return formatDateToUTC(dt, format);
        },
        chainDataCheck(companyDetails) {
            return companyDetails?.company?.chain_data?.entity?.filter((n) => n.owner).length && companyDetails?.company?.chain_data?.entity?.filter((n) => n.ultimate_beneficiary).length;
        },
        graphCheck(companyDetails) {
            return companyDetails?.company?.chain_data?.entity.length > 0 && companyDetails?.company?.chain_data?.entity.length > 0;
        },
        async fetchMoreInfo(details, api_call_type = null) {
            this.$emit("fetchMoreInfo", details, api_call_type);
            // this.updateCollapse(api_call_type);
        },
        openByDefault() {
            // companyDetails && companyDetails.company && companyDetails.company.financial_summary && (companyDetails.company.financial_summary.current_assets || companyDetails.company.financial_summary.fixed_assets)
        },
        handlePanelExpand(details, type, recall = true) {
            details[`${type}Expanded`] = !details[`${type}Expanded`];

            if (this.isComparisonModeOn && recall) {
                let otherVersion = this.$parent.$parent.$children.find((el) => el.companyDetails.api_all_data.doc_id !== this.companyDetails.api_all_data.doc_id);
                otherVersion = otherVersion.$children[otherVersion.$children.length - 1];
                otherVersion.handlePanelExpand(otherVersion.companyDetails, type, false);
            }

            if (
                details.mainDetailsCorpExpanded &&
                (details.detailsCorpExpanded || !(details && details.company && details.company.financial_summary && (details.company.financial_summary.current_assets || details.company.financial_summary.fixed_assets))) &&
                (details.ownershipCorpExpanded || !this.chainDataCheck(details)) &&
                (details.graphCorpExpanded || !this.graphCheck(details)) &&
                (details.alternativeandPreviousNamesExpanded || !(details && details.company && (details.company.alternative_names || details.company.previous_names))) &&
                (details.officersCorpExpanded || !(details && details.company && details.company.officers && details.company.officers.length > 0)) &&
                (details.filingCorpExpanded || !(details && details.company && details.company.filings && details.company.filings.length > 0))
            ) {
                details.expandedPanel = true;
            } else {
                details.expandedPanel = false;
            }
            this.$emit("updateExpandAll", details.expandedPanel);
            if (this.isComparisonModeOn && !recall)
                setTimeout(() => {
                    this.highlightChanges();
                }, 100);
            this.$forceUpdate();
        },
        klReady(chart) {
            this.chart = chart;
            this.initialiseInteractions();
            this.chart.options({handMode: true});
            this.chart.layout("sequential", {tightness: 3, orientation: "down"});
        },
        initialiseInteractions() {
            // this.chart.on("context-menu", ({ id }) => {
            //     this.showSmartAnalyser = true
            //     const node = this.chart.getItem(id);
            //     var viewCoords = this.chart.viewCoordinates(node.x, node.y)
            //     var fakeStartButton = this.$refs['analyze-popup']['$el']
            //     fakeStartButton.style.position = "absolute"
            //     fakeStartButton.style.left = `${viewCoords.x + 300}px`
            //     fakeStartButton.style.top = `${viewCoords.y}px`
            //     this.selectedValue = node.label
            //     // this.selectedSource = (node.nodeType === 'link') ?  'url' ? (node.nodeType === 'company')  : node.nodeType
            //     if (node.nodeType === 'link') {
            //         this.selectedSource = 'url'
            //     }
            //     else if (node.nodeType === 'company') {
            //         this.selectedSource = 'company_name'
            //     }
            //     else {
            //         this.selectedSource = node.nodeType
            //     }
            //     this.$refs['analyze-popup'].$el.click()
            //     // e.preventDefault();
            //     // this.resetAnalyzerOptions()
            // });
        },

        async StabilizeGraph(klId) {
            this.stabilizing = true;
            await window.KeyLines.charts[`kl_corporate_records${klId}`].layout("sequential", {tightness: 3, orientation: "down"});
            this.stabilizing = false;
        },
        async getPrintdata(id, filename) {
            await EventBus.$emit("handleExpandAllPanel", {
                details: this.companyDetails,
                key: "openCorporates",
                value: true,
            });
            // setTimeout(() => {
            this.$emit("updatePrintStatus", "");
            this.printData(id, filename);
            // this.$emit('printDocument', filename);
            // }, 2000);
        },
    },
};
