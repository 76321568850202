// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";
import NeoLoader from "@/components/loader";
import {DateTime} from "luxon";
import {mapActions} from "vuex";
import Tab from "@/components/tab-comments";
import Button from "@/components/button";
import Input from "@/components/input";

export default {
    name: "neo-comments",
    props: {
        id: {
            type: String,
            default: () => "",
        },
        selectedQuery: {
            type: Object,
            default: () => {},
        },
        type: {
            type: String,
        },
    },
    components: {
        NeoLoader,
        "neo-tab": Tab,
        "neo-button": Button,
        "neo-input": Input,
    },
    data() {
        return {
            loader: false,
            comment: {
                case_id: "",
                id: "",
                comment_text: "",
            },
            allcomments: [],
            readOnly: false,
            active_tab: "add-comment",
            tabs: [
                {
                    key: "add-comment",
                    name: "Add Comment",
                    enabled: true,
                    readOnly: true,
                },
                {
                    key: "all-comments",
                    name: "All Comments",
                    enabled: true,
                    readOnly: true,
                },
            ],
        };
    },
    computed: {
        comment_id() {
            if (this.selectedQuery) {
                let tool_name;
                if (this.$route.name == "monitoring") {
                    tool_name = "tools";
                } else {
                    tool_name = this.$route.name;
                }
                return tool_name + ":" + this.selectedQuery.tool + ":" + this.selectedQuery._id + ":" + this.id;
            } else {
                return "";
            }
        },
    },
    filters: {
        ConvertDate: function (value) {
            if (!value) return "";
            return DateTime.fromISO(value).toFormat("EEE, MMMM dd yyyy, hh:mm a");
            // let date = DateTime.fromISO(value, {zone: "Asia/Kolkata"}).toUTC();
            // return DateTime(date).toFormat("d MMMM yyyy hh:mm a");
        },
    },
    mounted() {
        if (this.$route.name) this.section_name = this.$route.name;
    },
    methods: {
        ...mapActions({
            addComment: "comment/addComment",
            getComments: "comment/getComment",
        }),
        AllComments() {
            let data = {};
            data.case_id = this.selectedQuery.case_id;
            data.comment_id = this.comment_id;
            this.loader = true;
            this.getComments(data)
                .then((response) => {
                    this.loader = false;
                    if (response.data.status == true) {
                        this.allcomments = response.data.data.sort(function (a, b) {
                            if (a.updated_at < b.updated_at) return 1;
                            else return -1;
                        });
                    }
                })
                .catch((error) => {
                    this.loader = false;
                });
        },
        handle_comment() {
            this.active_tab = "add-comment";
            document.body.classList.add("modal-open");
            this.$modal.show("inactive-modal-" + this.id + "-" + this.type, {"before-close": (event) => {}});
        },
        remove_class_body() {
            document.body.classList.remove("modal-open");
        },
        closeModal() {
            this.$modal.hide("inactive-modal-" + this.id + "-" + this.type);
        },
        onTopTabChangeComment(tab) {
            this.active_tab = tab;
            if (tab == "all-comments") {
                this.allcomments = [];
                this.AllComments();
            }
        },
        submit() {
            if (!this.comment.comment_text) {
                this.$toast.error("Please add comment");
                return;
            }
            if (this.selectedQuery) {
                this.comment.case_id = this.selectedQuery.case_id;
            }
            this.comment.id = this.comment_id;
            this.loader = true;
            this.addComment(this.comment)
                .then((response) => {
                    this.loader = false;
                    this.$toast.success("Comment added successfully");
                    this.comment = {
                        comment_text: "",
                    };
                    this.closeModal();
                })
                .catch((error) => {
                    this.$toast.error("Something went wrong while creating new comment");
                    this.loader = false;
                    this.closeModal();
                });
        },
    },
};
