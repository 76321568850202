import NeoAnalyse from "@/components/analyse";
import {DateTime} from "luxon";
import NeoLoader from "@/components/loader";
import Tags from "@/components/tags";
import Tag from "@/components/tag";
import {get, startCase} from "lodash";
import {KlChart} from "@/components/vue-keylines";
import printDataMixin from "@/mixins/printDataMixin";
import highlighterMixin from "@/mixins/highlighterMixin";
import {treemapSquarify} from "d3-hierarchy";
import {EventBus} from "@/main.js";
export default {
    name: "clarifiedBy",
    components: {
        NeoAnalyse,
        NeoLoader,
        "neo-tags": Tags,
        "neo-tag": Tag,
        KlChart,
    },
    props: {
        companyDetails: {type: Object},
        isComparisonModeOn: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [printDataMixin, highlighterMixin],
    data() {
        return {
            startCase: startCase,
            stabilizing: false,
            refBtnClick: {
                clarifiedBysummary: true,
                clarifiedByOwnership: true,
                clarifiedByManagement: true,
                clarifiedByHoldings: true,
                clarifiedByActivities: true,
            },
            emitVal: {
                detail: false,
                ownership: false,
                management: false,
                holdings: false,
                activities: false,
            },
            isFetchData: false,
            printId: "",
            printName: "",
            isPrinted: false,
        };
    },
    computed: {},
    watch: {},
    mounted() {
        this.highlightChanges();
    },
    methods: {
        get,
        getAnalyserSource(key, matchKeyword) {
            if (key && typeof key === "string" && key?.toLowerCase() === matchKeyword) return "name";
            else return "company_name";
        },
        commaJoin(...values) {
            return values.filter((e) => e != null && e != "").join(", ");
        },
        joinArray(arr, sep = ", ") {
            return arr.filter((e) => e != null && e != "").join(sep);
        },
        formatDateFromISO(dt, format = "d MMMM yyyy") {
            return DateTime.fromISO(dt).toFormat(format);
        },
        formatDate(dt) {
            let parsed_dt = DateTime.fromISO(dt);
            if (!parsed_dt.invalid) return parsed_dt.toFormat("EEE, MMM dd yyyy");
            return dt;
        },
        async fetchMoreInfo(details, api_call_type = null) {
            await this.emitPromise("fetchMoreInfo", details, api_call_type);
            // await this.updateType(api_call_type)
            return true;
        },
        async updateType(api_call_type = null) {
            var self = this;
            if (api_call_type != null) {
                self.emitVal[api_call_type] = true;
            }
            var falCount = 0;
            Object.keys(self.emitVal).forEach(function (key) {
                if (self.emitVal[key] == false) {
                    falCount = falCount + 1;
                }
            });
            if (falCount == 0) {
                self.isFetchData = true;
            }
        },
        async emitPromise(method, ...params) {
            let listener = this.$listeners[method] || this.$attrs[method] || this[method];
            if (listener) {
                //one can additionally wrap this in try/catch if needed and handle the error further
                let res = await listener(...params);
                return res === undefined || res;
            }
            return false;
        },
        handlePanelExpand(details, type, recall = true) {
            this.updateCollapse(type);
            details[`${type}Expanded`] = !details[`${type}Expanded`];

            if (this.isComparisonModeOn && recall) {
                let otherVersion = this.$parent.$parent.$children.find((el) => el.companyDetails.api_all_data.doc_id !== this.companyDetails.api_all_data.doc_id);
                otherVersion = otherVersion.$children[otherVersion.$children.length - 1];
                otherVersion.handlePanelExpand(otherVersion.companyDetails, type, false);
            }

            if (details.summaryExpanded && details.ownershipExpanded && details.managementExpanded && details.holdingsExpanded && details.activitiesExpanded) {
                details.expandedPanel = true;
            } else {
                details.expandedPanel = false;
            }
            this.$emit("updateExpandAll", details.expandedPanel);
            if (this.isComparisonModeOn && !recall)
                setTimeout(() => {
                    this.highlightChanges();
                }, 100);
            this.$forceUpdate();
        },
        klReady(chart) {
            this.chart = chart;
            this.initialiseInteractions();
            this.chart.options({handMode: true});
            this.chart.layout("sequential", {tightness: 3, orientation: "down"});
        },
        initialiseInteractions() {
            // this.chart.on("context-menu", ({ id }) => {
            //     this.showSmartAnalyser = true
            //     const node = this.chart.getItem(id);
            //     var viewCoords = this.chart.viewCoordinates(node.x, node.y)
            //     var fakeStartButton = this.$refs['analyze-popup']['$el']
            //     fakeStartButton.style.position = "absolute"
            //     fakeStartButton.style.left = `${viewCoords.x + 300}px`
            //     fakeStartButton.style.top = `${viewCoords.y}px`
            //     this.selectedValue = node.label
            //     // this.selectedSource = (node.nodeType === 'link') ?  'url' ? (node.nodeType === 'company')  : node.nodeType
            //     if (node.nodeType === 'link') {
            //         this.selectedSource = 'url'
            //     }
            //     else if (node.nodeType === 'company') {
            //         this.selectedSource = 'company_name'
            //     }
            //     else {
            //         this.selectedSource = node.nodeType
            //     }
            //     this.$refs['analyze-popup'].$el.click()
            //     // e.preventDefault();
            //     // this.resetAnalyzerOptions()
            // });
        },
        async StabilizeGraph(klId) {
            this.stabilizing = true;
            await window.KeyLines.charts[`kl_corporate_records${klId}`].layout("sequential", {tightness: 3, orientation: "down"});
            this.stabilizing = false;
        },
        async getPrintdata(id, filename) {
            await EventBus.$emit("handleExpandAllPanel", {
                details: this.companyDetails,
                key: "clarifiedBy",
                value: true,
            });
            // setTimeout(() => {
            this.$emit("updatePrintStatus", "");
            this.printData(id, filename);
        },
        async checkPrintData(id, filename) {
            if (this.isPrinted && this.isFetchData) {
                // setTimeout(() => {
                var self = this;
                self.$emit("updatePrintStatus", "");
                self.printData(id, filename);
                // this.$emit('printDocument', filename);
                self.isPrinted = false;
                // }, 2000);
            }
            // var falCount = 0
            // var self = this
            // Object.keys(self.emitVal).forEach(function (key) {
            //     if (self.emitVal[key] == false) {
            //         falCount = falCount + 1
            //     }
            // });
            // if (falCount == 0) {
            //     setTimeout(() => {
            //         self.isFetchData = true
            //         self.$emit("updatePrintStatus", '');
            //         self.printData(id, filename);
            //         EventBus.$off('printResp')
            //     }, 2000)
            // }
        },

        updateCollapse(type) {
            if (type == "summary") {
                this.$set(this.refBtnClick, "clarifiedBysummary", !this.refBtnClick.clarifiedBysummary);
            } else if (type == "ownership") {
                this.$set(this.refBtnClick, "clarifiedByOwnership", !this.refBtnClick.clarifiedByOwnership);
            } else if (type == "management") {
                this.$set(this.refBtnClick, "clarifiedByManagement", !this.refBtnClick.clarifiedByManagement);
            } else if (type == "holdings") {
                this.$set(this.refBtnClick, "clarifiedByHoldings", !this.refBtnClick.clarifiedByHoldings);
            } else if (type == "activities") {
                this.$set(this.refBtnClick, "clarifiedByActivities", !this.refBtnClick.clarifiedByActivities);
            }
        },
    },
};
